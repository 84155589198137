ol li:hover {
  color: #02aab0;
  cursor: pointer;
}

li {
  font-size: 20px;
  font-family: Roboto;
}

a {
  font-size: 20px;
  font-family: Roboto;
}

ul {
  list-style-type: disc;
}

ol li {
  display: block;
} /* hide original list counter */
ol > li:first-child {
  counter-reset: item;
} /* reset counter */
ol > li {
  counter-increment: item;
  position: relative;
} /* increment counter */
ol > li:before {
  content: counters(item, ".") ". ";
  position: absolute;
  margin-right: 100%;
  right: 10px;
} /* print counter */
